import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from "@azure/msal-angular";
import { EventMessage, EventType, RedirectRequest } from "@azure/msal-browser";
import { Observable, Subject } from "rxjs";
import { filter, takeUntil, tap } from "rxjs/operators";
import { HttpService } from "../common/http.service";
import { NotificationService } from "../common/NotificationService";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  baseUrl: string;
  private readonly _destroying$ = new Subject<void>();
  isUserAccess: boolean = false;
  constructor(private httpService: HttpService, @Inject('BASE_URL') baseUrl: string,
    private notificationService: NotificationService,
    private msalService: MsalService,
    private broadcastService: MsalBroadcastService,
    private router: Router,
  ) {
    this.baseUrl = baseUrl;
  }
  hasAdminAccess(username): Observable<boolean> {
    const url = this.baseUrl + "authorization/isUserHasAdmin/"+username;
    return this.httpService.get<any>(url);
  }

  checkAndSetActiveUserAccount() {
    this.msalService.instance.handleRedirectPromise().then((res) => {
      if (res != null && res.account != null) {
        this.msalService.instance.setActiveAccount(res.account);
        this.hasAdminAccess(res?.account?.username).subscribe(data => {

          !data ? this.router.navigateByUrl('/noaccess') : this.isUserAccess = true;
        },
          error => {
            this.router.navigateByUrl('/noaccess');
          }
         );
      } else {
        this.authUserLogin();
      }
    });
    this.broadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe();
  }
  authUserLogin() {
    this.msalService.loginRedirect({
      scopes: ["User.Read"]
    });
  }
}
