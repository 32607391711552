<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Delete HazMat Information</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModel.close(false)">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">

  <p>
    Do you want to delete all HazMat information for product number {{productNumber}}?
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn red-btn" (click)="activeModel.close(true)">Yes</button>
  <button type="button" class="btn red-btn" (click)="activeModel.close(false)">No</button>
</div>
