import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  isExpanded = false;
  collapse() {
    this.isExpanded = false;
  }
  constructor(private router:Router) {

  }

  isValidHazMatTool() {
    return this.router.url.includes('/hazmatTool') || this.router.url.includes('/hazmatInfo') || this.router.url.includes('/noaccess')
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
