<div class="container hazmat-tool">
  <div *ngIf="hazmatProduct">
    <div class="row">
      <div class="col-12">
        <div class="form-group form-group-sm">
          <p>
            Select Hazmat values for your product
          </p>
          <p>
            Product No: {{productNumber}}
          </p>
          <p>
            Product Name: {{hazmatProduct?.ProductName}}
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
      <form name="mainForm" [formGroup]="hazmatform">
        <div class="row">
          <div class="col-md-6 col-lg-6 col-xs-12 p-0 mb-10">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h3 class="panel-title">Hazmat</h3>
              </div>
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-4 col-lg-4 col-xs-12">
                    <div class="form-group form-group-sm">
                      <label class="control-label" for="class">Class</label>
                      <input id="pclass"
                             type="text"
                             class="form-control"
                             maxlength="3"
                             [(ngModel)]="hazmatProduct.Class"
                             name="productclass"
                             pattern="[0-9\.]{0,3}"
                             formControlName="class"
                             TwoDigitDecimaNumber
                             title='maximum three characters 0-9 and "."' />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <!-- packing group -->
                  <div class="col-md-6 col-lg-6 col-xs-12">
                    <div class="form-group form-group-sm">
                      <label class="control-label" for="packingGroup">Packing Group</label>
                      <select id="packingGroup" class="form-control" [(ngModel)]="hazmatProduct.PackingGroupId" name="packinggroupid" formControlName="packingGroupId">
                        <option></option>
                        <option *ngFor="let pgroup of masterData.HazmatPackingGroups" [value]="pgroup.HazMatPackingGroupId">{{pgroup.HazMatPackingGroupName}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <!-- UN Code -->
                  <div class="col-md-5 col-lg-4 col-xs-12">
                    <div class="form-group form-group-sm">
                      <label class="control-label" for="unCode">UN Code</label>
                      <select id="unCode" class="form-control" [(ngModel)]="hazmatProduct.UnCode" name="unCodes" formControlName="unCode" (change)="Onchangeuncode($event)">
                        <option [value]=null>No Value</option>
                        <option *ngFor="let pgroup of masterData.HazmatUNCodes" [value]="pgroup.UNCode">{{pgroup.UNCode}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-10 col-lg-8 col-xs-12">
                    <div class="form-group form-group-sm">
                      <label class="control-label" for="dgDesc">Dangerous Goods Description</label>
                      <input id="dgDesc" type="text" [value]="dgDescription" name="dgDescc" class="form-control" disabled="disabled" title={{dgDescription}} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xs-12 pr-10 mb-10">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h3 class="panel-title">Emergency</h3>
              </div>
              <div class="panel-body">
                <div class="row">
                  <!-- emergency schedule no 1 -->
                  <div class="col-md-9 col-lg-6 col-xs-12">
                    <div class="form-group form-group-sm">
                      <label class="control-label" for="emcScheduleNo1">Emergency Schedule No 1</label>
                      <input id="emcScheduleNo1"
                             type="text"
                             class="form-control"
                             placeholder="No value"
                             maxlength="7"
                             [(ngModel)]="hazmatProduct.EmergencyScheduleNumberOne" 
                             name="emergencyScheduleNumberOne"
                             formControlName="emergencyScheduleNumberOne"
                             title='seven characters' />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <!-- emergency schedule no 2 -->
                  <div class="col-md-9 col-lg-6 col-xs-12">
                    <div class="form-group form-group-sm">
                      <label class="control-label" for="emcScheduleNo2">Emergency Schedule No 2</label>
                      <input id="emcScheduleNo2"
                             type="text"
                             class="form-control"
                             placeholder="No value"
                             maxlength="7"
                             [(ngModel)]="hazmatProduct.EmergencyScheduleNumberTwo"
                             name="emergencyScheduleNumberTwo"
                             formControlName="emergencyScheduleNumberTwo"
                             title='seven characters' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-6 col-lg-6 col-xs-12 p-0 mb-10">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h3 class="panel-title">Volume</h3>
              </div>
              <div class="panel-body">
                <div class="row">
                  <!-- volume -->
                  <div class="col-md-3 col-lg-4 col-xs-12">
                    <div class="form-group form-group-sm">
                      <label class="control-label" for="volume">Volume</label>
                      <input id="volume"
                             type="text"
                             class="form-control"
                             maxlength="9"
                             [(ngModel)]="hazmatProduct.Volume"
                             name="volume"
                             placeholder="No value"
                             ThreeDigitDecimaNumber
                             formControlName="volume"
                             title='0-99999.999'
                             (input)="OnChangeVolume($event)"
                             />
                    </div>
                  </div>
                  <!-- unit of volume -->
                  <div class="col-md-6 col-lg-4 col-xs-12">
                    <div class="form-group form-group-sm">
                      <label class="control-label" for="unitOfVolume">Unit of Volume</label>
                      <select id="unitOfVolume" class="form-control" name="hazvolumeUnitId" [(ngModel)]="hazmatProduct.VolumeUnitId" formControlName="volumeUnitId" (change)="OnchangeUnitVolume($event)" >
                        <option></option>
                        <option *ngFor="let pgroup of masterData.HazmatContentVolumeUOMs" [value]="pgroup.HazmatContentVolumeUOMId">{{pgroup.HazmatContentVolumeUOMName}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xs-12 pr-10 mb-10">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h3 class="panel-title">Temperature</h3>
              </div>
              <div class="panel-body">
                <div class="row">
                  <!-- flash point -->
                  <div class="col-md-5 col-lg-3 col-xs-12">
                    <div class="form-group form-group-sm">
                      <label class="control-label" for="flashPoint">Flash Point</label>
                      <input id="flashPoint"
                             type="number"
                             class="form-control"
                             pattern="-?([0-9]{0,6})"
                             title="number"
                             placeholder="No value"
                             name="flashPoint"
                             NumbersWithMinus
                             formControlName="flashPoint"
                             [(ngModel)]="hazmatProduct.FlashPoint"
                              (keyup)="onflashPointChange($event)" />
                    </div>
                  </div>
                  <!-- unit of temperature -->
                  <div class="col-md-7 col-lg-5 col-xs-12">
                    <div class="form-group form-group-sm">
                      <label class="control-label" for="unitOfTemperature">Unit of Temperature</label>
                      <select id="unitOfTemperature" class="form-control" name="temperatureUnitId" [(ngModel)]="hazmatProduct.TemperatureUnitId"
                              formControlName="temperatureUnitId" (change)="OnchangeUnitTemp($event)">
                        <option></option>
                        <option *ngFor="let pgroup of masterData.HazmatTemperatureUnits" [value]="pgroup.HazmatTemperatureUnitId">{{pgroup.HazmatTemperatureUnitDescription}} ({{pgroup.HazmatTemperatureUnitName}})</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-8 col-lg-5 col-xs-12">
                    <div class="form-group form-group-sm">
                      <label class="control-label" for="measurementMethod">Measurement Method</label>
                      <select id="measurementMethod" class="form-control" name="measurementMethodId" [(ngModel)]="hazmatProduct.MeasurementMethodId" formControlName="measurementMethodId">
                        <option></option>
                        <option *ngFor="let pgroup of masterData.HazmatMeasurementMethods" [value]="pgroup.HazmatMeasurementMethodId">{{pgroup.HazmatMeasurementMethodName}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
        <div class="row mt-3">
          <div class="col-md-11 col-lg-11 col-xs-12 mb-2">
            <button class="btn btn-primary pull-right ml-3" (click)="submit()" [disabled]="!(hazmatform.valid && hazmatform.dirty)">Save</button>
            <button class="btn btn-default pull-right"  [routerLink]="['/hazmatTool']" style="margin-right:5px">Cancel</button>
            <button class="btn pull-right mr-3 delete-btn" style="margin-bottom: 10px;" (click)="deleteHazmatProduct()" *ngIf="this.hazmatProduct.HazmatProductId!=0">Delete</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
