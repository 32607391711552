import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../common/NotificationService';
import { DeleteProductComponent } from '../delete-product/delete-product.component';
import { ProductService } from '../product.service';

@Component({
  selector: 'product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.css']
})
export class ProductInfoComponent implements OnInit {
  productNumber: string;
  hazmatform: FormGroup;
  subscription = new Subscription();
  dgDescription: any = null;
  temperatureUnit: any;
  measurementMethod: any;
  isProductInfo: boolean = false;
  masterData: any;
  hazmatProduct: Hm.Bopo.HazmatSearch.Core.ViewData.HazmatProductInfoViewData;
  oldhazmatProduct: Hm.Bopo.HazmatSearch.Core.ViewData.HazmatProductInfoViewData;
  constructor(public dialog: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private fbfind: FormBuilder,
    private productService: ProductService,
    private notificationService: NotificationService) {
  }

  async ngOnInit(): Promise<void> {
    await this.productService.GetMasterDataConfiguration().toPromise();
    this.productNumber = this.route.snapshot.params['id'];
    this.masterData = this.productService.masterData;
    this.hazmatform = this.setFormgroup();
    this.updateHazmatProductInfo();
  }

  transform(value: any): any {
    return value ? parseFloat(value).toFixed(3) : null;
  }
  submit() {
    if (this.hazmatProduct.HazmatProductId === 0) {
      this.addproductInfo();
    }
    else {
      this.editproductInfo();
    }
  }
  setFormgroup() {
    return this.fbfind.group({
      hazmatProductId: [null],
      productId: [null],
      productName: [null],
      class: [null],
      packingGroupId: [null],
      flashPoint: [null],
      temperatureUnitId: [null],
      measurementMethodId: [null],
      volume: [null],
      volumeUnitId: [null],
      unCode: [null],
      emergencyScheduleNumberOne: [null],
      emergencyScheduleNumberTwo: [null],
    });
  }
  async updateHazmatProductInfo(): Promise<void> {
    await this.productService.GetProductInfo(this.productNumber).toPromise();
    if (this.productService.hazmatProductInfo) {
      this.hazmatProduct = this.productService.hazmatProductInfo;
      this.hazmatProduct.ProductId = Number(this.productNumber);
      this.Onchangeuncode(this.productService.hazmatProductInfo?.UnCode);
      this.hazmatform
        .get('productId')
        .setValue(Number(this.productNumber));
      this.hazmatform
        .get('hazmatProductId')
        .setValue(this.hazmatProduct.HazmatProductId);
      this.hazmatform
        .get('productName')
        .setValue(this.hazmatProduct.ProductName);
      this.hazmatProduct.Volume = this.transform(this.hazmatProduct.Volume);
    }
  }

  addproductInfo() {
    this.hazmatform.markAllAsTouched();
    if (this.hazmatform.valid) {
      this.subscription.add(this.productService.AddHazmatProduct(this.hazmatProduct).subscribe(res => {
        this.notificationService.success("Saved Successfully");
        this.changeDropDownValues();
        this.updateHazmatProductInfo();
      }));
    }
  }

  editproductInfo() {
    this.oldhazmatProduct = this.hazmatProduct;
    this.subscription.add(this.productService.EditHazmatProduct(this.hazmatProduct).subscribe(res => {
      this.notificationService.success("Updated Successfully");
      this.changeDropDownValues();
      this.updateHazmatProductInfo();
    }));
  }

  changeDropDownValues() {
    this.hazmatform.markAsPristine();
  }
  deleteproductInfo(productNumber) {
    this.subscription.add(this.productService.DeletetHazmatProduct(productNumber).subscribe(res => {
      this.notificationService.success("Deleted Successfully");
      this.router.navigateByUrl('/hazmatTool');
    }));
  }
  deleteHazmatProduct(): void {
    const dialogRef = this.dialog.open(DeleteProductComponent, {
      centered: false,
      backdrop: false,

    });
    dialogRef.componentInstance.productNumber = this.productNumber;
    dialogRef.closed.toPromise().then(result => {
      if (result) {
        this.deleteproductInfo(this.productNumber);
      }
    });
  }
  OnChangeVolume(data) {
    let resultVolume = data.target.value;
    if (resultVolume) {
      this.hazmatProduct.VolumeUnitId = 1
    }
    else {
      this.hazmatProduct.VolumeUnitId = null;
    }
  }
  OnchangeUnitVolume(data) {
    if (!data.target.value) {
      this.hazmatProduct.Volume = null;
      this.hazmatform.patchValue({
        volume: null,
      });
    }
  }
  OnchangeUnitTemp(data) {
    if (!data.target.value) {
      this.hazmatProduct.FlashPoint = null;
    }
  }
  onflashPointChange(data) {
    let resultflashPoint = data.target.value;
    if (resultflashPoint) {
      this.hazmatProduct.TemperatureUnitId = 1;
      this.hazmatProduct.MeasurementMethodId = 1;
    }
    else {
      this.hazmatProduct.TemperatureUnitId = null;
      this.hazmatProduct.MeasurementMethodId = null;
    }
  }
  ngOnDestroy() {
    this.isProductInfo = false;
    this.subscription.unsubscribe();
  }

  Onchangeuncode(event) {
    let code = event ? event.target ? event.target.value == "null" ? null : event.target.value : event : null;
    if (event && event.target && event.target.value) {
      this.hazmatProduct.UnCode = event.target.value == "null" ? null : event.target.value;
    }
    if (event) {
      this.dgDescription = this.masterData?.HazmatUNCodes?.filter(f => f.UNCode === code)[0]?.DGDescription ?? null;
    }
  }

}
