<mat-card class="hazmat-card">
  <div class="center">
    <div class="col-md-9">
      <h1>Hazardous Materials Search</h1>
      <p>
        Safety Data Sheets (SDS) are available for products supplied by H&M Group when required by law. An SDS describes the hazards of a product and provides
        information on how a product can be safely handled, used and stored.
      </p>
      <p>To search for a specific SDS you must fill either <strong>Product Number </strong>or <strong>Order Number </strong>.<br></p>
    </div>
    <div class="container">
      <div class="column" style="margin-top:20px">
        <form class="hazmat-form" [formGroup]="SDSsearchData">
          <mat-form-field class="hazmat-form-full-width">
            <mat-label class="productno">Product Number</mat-label>
            <input matInput formControlName="productId"
                   placeholder="Ex.0229436">
            <mat-error *ngIf="(SDSsearchData.get('productId').errors?.required && SDSsearchData.get('orderId').errors?.required) && (SDSsearchData.get('productId').touched || SDSsearchData.get('productId').dirty)">
              Product Or Order Number is required
            </mat-error>
          </mat-form-field>


          <mat-form-field class="hazmat-form-full-width">
            <mat-label class="orderno">Order Number</mat-label>
            <input matInput formControlName="orderId"
                   placeholder="Ex.975760">
            <mat-error *ngIf="(SDSsearchData.get('orderId').errors?.required && SDSsearchData.get('productId').errors?.required) && (SDSsearchData.get('orderId').touched || SDSsearchData.get('orderId').dirty)">
              Product Or Order Number is required
            </mat-error>
          </mat-form-field>

          <button style="background-color: #700100; margin-right: 5px; color: white" mat-raised-button matTooltip="Find SDS" (click)="searchDocument()" class="hazmat-form-full-width">
            Find &nbsp;
            <i class="fas fa-search" style="font-size:14px;"></i>
          </button>
          <button style="background-color: #700100; color: white" mat-raised-button matTooltip="Reset filters" (click)="resetFilters()" class="hazmat-form-full-width">
            Reset &nbsp;
            <i class="fas fa-undo" style="font-size:14px;"></i>
          </button>
          <mat-divider style="margin-top: 15px;"></mat-divider>
          <mat-spinner *ngIf="isLoading" class="spinner-alignment"></mat-spinner>
        </form>
        <div style="margin-top: 30px;">
          <mat-card *ngFor="let document of hazmatDocuments" class="matcard" (click)="downloadSDS(document)">
            <mat-card-header style="height: 20px">
              <div style="float: left;width:93%">
                <mat-card-title> {{document.DocumentName}}</mat-card-title>
              </div>
              <div style="text-align: center;width:7%">
                <span *ngIf="document.FileType?.toLowerCase() =='jpg' || document.FileType?.toLowerCase() =='png'">
                  <i class="fas fa-file-image" style="font-size:24px; margin-right: 5px;"></i>
                </span>
                <span *ngIf="document.FileType?.toLowerCase()  =='doc' || document.FileType?.toLowerCase() =='docx' || document.FileType?.toLowerCase() =='rtf'">
                  <i class="fas fa-file-word" style="font-size:24px;color:blue;margin-right: 5px;"></i>
                </span>
                <span *ngIf="document.FileType?.toLowerCase() =='xls' || document.FileType?.toLowerCase() =='xlsx' || document.FileType?.toLowerCase() =='xlsm'">
                  <i class="fas fa-file-excel" style="font-size:24px;color:green;margin-right: 5px;"></i>
                </span>
                <span *ngIf="document.FileType?.toLowerCase() =='pdf' ">
                  <i class="fas fa-file-pdf" style="font-size:24px;color:red;margin-right: 5px;"></i>
                </span>
                <span *ngIf="document.FileType?.toLowerCase() =='zip' ">
                  <i class="fas fa-file-archive" style="font-size:24px;margin-right: 5px;"></i>
                </span>
                <span *ngIf="!(document.FileType.toLowerCase() =='jpg' || document.FileType.toLowerCase() =='png'|| document.FileType.toLowerCase()  =='doc' || document.FileType.toLowerCase() =='docx' || document.FileType.toLowerCase() =='rtf'
                          || document.FileType.toLowerCase() =='xls' || document.FileType.toLowerCase() =='xlsx' || document.FileType.toLowerCase() =='xlsm'
                          || document.FileType.toLowerCase() =='pdf' || document.FileType.toLowerCase() =='zip')">
                  <i class="fas fa-file" style="font-size:24px;margin-right: 5px;"></i>
                </span>
              </div>
            </mat-card-header>
            <mat-card-content>
              <div>
                <ul>
                  <li><i class="fa fa-tshirt"></i>{{document.ProductNumber}} </li>
                  <li><i class="fa fa-list-alt"></i>{{document.OrderNumber}} </li>
                  <li><strong>{{getSeasonDisplayText(document.Season.SeasonNumber9,document.Season.SeasonYear)}}</strong></li>
                  <li>{{document.ProductName}} </li>
                  <li style="float: right">{{document.Language.LanguageName}}</li>
                 </ul>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</mat-card>
