import { environment } from '../../environments/environment';
import { IPublicClientApplication, PublicClientApplication, BrowserCacheLocation, InteractionType } from '@azure/msal-browser';
import { MsalInterceptorConfiguration, MsalGuardConfiguration } from '@azure/msal-angular';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: getAppClientId(),
      authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8/',
      redirectUri: '/hazmatTool',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE,
    },
    system: {
      allowNativeBroker: false, // Disables native brokering support
    }
  });
}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  protectedResourceMap.set(getAppRootUrl() + '/access_api', [getAppClientId() + '/user_impersonation']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function getAppClientId(): string {
  let clientId: string = '';
  const appEnv = getAppEnvironment();
  if (appEnv.includes('dev')) {
    clientId = '8fe7dfd5-bf91-4164-9c76-1f64b0607e44';
  } else if (appEnv.includes('sit')) {
    clientId = '5c3bb628-aab1-4797-8ee5-8f497babfb44';
  }else {
    clientId = '68ba503a-e426-41a8-9735-fcf773d0166c';
  }
  return clientId;
}

export function getAppEnvironment(): string {
  let appEnv = 'dev';
  const hostname: string = window.location.hostname;
  const hostNameToArray: string[] = hostname.split('.');
  if (hostNameToArray.length > 0) {
    appEnv = hostNameToArray[0];
  }
  return appEnv == 'localhost' ? 'dev' : appEnv;
}


export function getAppRootUrl(): string {
  return window.location.origin;
}
