import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  public readonly AUTHORISATION: string = 'Authorization';
  public readonly BEARER: string = 'bearer';
  private readonly DATA_RESP: string = 'json';
  constructor(private http: HttpClient) {

  }

  public get<T>(url: string): Observable<any> {
    let options: any = this.getHeaders();
    return this.http.get<T>(url, options);
  }

  public post<T>(url: string, responseBody: any): Observable<any> {
    let options: any = this.getHeaders();
    return this.http.post<T>(url, responseBody, options);
  }
  public patch<T>(url: string, data: any): Observable<any> {
    let options: any = this.getHeaders();
    return this.http.patch<T>(url, data, options);
  }

  public delete<T>(url: string): Observable<any> {
    let options: any = this.getHeaders();
    return this.http.delete<T>(url,options);
  }

  public put<T>(url: string, data: any): Observable<any> {
    let options: any = this.getHeaders();
    return this.http.put<T>(url, data,options);
  }

  public postForBlob(url: string, data: any): Observable<Blob> {
    const postData = (<any>window).resolveJsonReferences(data);
    return this.http.post(url, postData, { observe: 'body', responseType: 'blob' });
  }

  public getBlob(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }

  public getHeaders() {
    let headersOption: HttpHeaders = new HttpHeaders()
      .set(
        'Access-Control-Allow-Headers',
        'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
      )
      .set('Access-Control-Allow-Methods', 'DELETE, POST, GET, OPTIONS');
    return { headers: headersOption, responseType: this.DATA_RESP };
  }
  public handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError('Something bad happened; please try again later.');
  }
}
