import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NoAccessComponent } from "./HazmatTool/no-access/no-access.component";
import { ProductInfoComponent } from "./HazmatTool/product-info/product-info.component";
import { ProductSearchComponent } from "./HazmatTool/product-search/product-search.component";
import { SearchComponent } from "./search/search.component";

const routes: Routes = [
  { path: '', component: SearchComponent, pathMatch: 'full' },
  { path: 'hazmatTool', component: ProductSearchComponent },
  { path: 'hazmatInfo/:id', component: ProductInfoComponent },
  { path: 'noaccess', component: NoAccessComponent },
]
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class appRoutingModule { }
