import { Injectable, Inject } from "@angular/core";
import { Observable} from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { HttpService } from "../common/http.service";
import { NotificationService } from "../common/NotificationService";

@Injectable({
  providedIn: 'root'
})

export class ProductService {
  private baseUrl = "";
  masterData;
  hazmatProductInfo;
  constructor(private httpService: HttpService, @Inject('BASE_URL') baseUrl: string,
    private notificationService: NotificationService) {
    this.baseUrl = baseUrl;
  }

  GetProductInfo(productNumber): Observable<any> {
    const hazmatUrl = this.baseUrl + "hazmat/getProductInfo/";
    return this.httpService.get<any>(hazmatUrl + productNumber).pipe(
      tap(data => {
        this.hazmatProductInfo = data;
      }),
      catchError(error => {
        this.notificationService.showNotification("Product "+productNumber + " not found ");
        return null;
      }));
  }

  GetMasterDataConfiguration(): Observable<any> {
    const hazmatUrl = this.baseUrl + "hazmat/getMasterDataConfiguration";
    return this.httpService.get<any>(hazmatUrl).pipe(
      tap(data => {
        this.masterData = data;
        return data;
      }));
  }

  AddHazmatProduct(data): Observable<any> {
    const hazmatUrl = this.baseUrl + "hazmat/addHazmatInfo";
    return this.httpService.post<any>(hazmatUrl, data);
  }

  EditHazmatProduct(data): Observable<any> {
    const hazmatUrl = this.baseUrl + "hazmat/editHazmatInfo";
    return this.httpService.patch<any>(hazmatUrl, data);
  }

  DeletetHazmatProduct(productNumber): Observable<any> {
    const hazmatUrl = this.baseUrl + "hazmat/deleteHazmatInfo/";
    return this.httpService.delete<any>(hazmatUrl + productNumber);
  }

}
