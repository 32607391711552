import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormsModule } from '@angular/forms';
import { SearchService } from "./search.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
})

export class SearchComponent implements OnInit {
  SDSsearchData: FormGroup;
  @ViewChild('allLanguagesSelected', { static: false }) private allLanguagesSelected: MatOption;

  public baseUrl = "";
  public seasons!: Hm.Bopo.HazmatSearch.Core.ViewData.SeasonViewData[];
  public languages!: Hm.Bopo.HazmatSearch.Core.ViewData.LanguageViewData[];
  public selectedLanguages!: Hm.Bopo.HazmatSearch.Core.ViewData.LanguageViewData[];
  public selectedSeason!: Hm.Bopo.HazmatSearch.Core.ViewData.SeasonViewData;
  public searchCriteria!: Hm.Bopo.HazmatSearch.Core.ViewData.SearchViewData;
  public hazmatDocuments!: Hm.Bopo.HazmatSearch.Core.ViewData.HazmatDocumentViewData[];
  public isLoading!: Boolean;

  displayedColumns: string[] = ['documentName', 'productName', 'productNumber', 'orderNumber', 'seasonNumber', 'actionsColumn'];
  dataSource = this.hazmatDocuments;

  constructor(private searchService: SearchService, @Inject('BASE_URL') baseUrl: string, private fbfind: FormBuilder, private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.loadSeasons();
    this.loadLanguages();
    this.initSearchCriteria();
    this.resetForm();
  }

  initSearchCriteria() {
    this.searchCriteria = {
      OrderNumber: null,
      ProductNumber: null,
      Languages:null,
      SeasonNumber: null
    };
  }

  resetForm() {
    this.SDSsearchData = this.fbfind.group({
      productId: ['', Validators.required],
      orderId: ['', Validators.required],
      seasons: [''],
      languages: ['']
    });
  }

  loadSeasons() {
    this.searchService.getSearchableSeasons()
      .subscribe(
        data => {
          this.seasons = data;
          this.selectedSeason = this.seasons[0];
          // TODO: Spinner loading
        },
        err => {
          // TODO: error handling
        });
  }

  loadLanguages() {
    this.searchService.getLanguages()
      .subscribe(
        data => {
          this.languages = data;
          // TODO: Spinner loading
        },
        err => {
          // TODO: error handling
        });
  }
  getSeasonDisplayText(seasonNumber, seasonYear) {
    return seasonNumber + "-" + seasonYear;
  }

  resetFilters() {
    this.resetForm();
  }

  searchDocument() {
    if (!this.isValidProductNumber() && this.SDSsearchData.value.productId.length > 0) {
      this.showNotification("Product Number should be 7 digits - xxxxxxx");
    }
    else if (!this.isValidOrderNumber() && this.SDSsearchData.value.orderId.length > 0) {
      this.showNotification("Order number should be 6 digits - xxxxxx");
    }
    else if (this.hasValidSearchCriteria(this.SDSsearchData.value)) {
      this.isLoading = true;
      this.setSearchCriteria(this.SDSsearchData.value);
      this.searchService.searchSDSDocuments(this.searchCriteria)
        .subscribe(
          data => {
            this.isLoading = false;
            this.hazmatDocuments = data;
            if (data.length === 0) {
              this.showNotification("No document(s) found.");
            }
          },
          err => {
            this.isLoading = false;
          });
    } else {
      this.showNotification("Product number or Order number is required.");
    }
  }

  setSearchCriteria(values) {
    
    this.searchCriteria.ProductNumber = values.productId;
    this.searchCriteria.OrderNumber = values.orderId;
    
  }

  

  showNotification(message: string) {
    this.snackBar.open(message, 'OK', {
      duration: 7000,
      panelClass: 'snackbar'
    });
  }
  isValidProductNumber() {
    var regexp = new RegExp('^[0-9][0-9]{6}$');
    if (regexp.test(this.SDSsearchData.value.productId)) {
      return true;
    }
    else {
      return false;
    }
  }
  isValidOrderNumber() {
    var regexp = new RegExp('^[0-9][0-9]{5}$');
    if (regexp.test(this.SDSsearchData.value.orderId)) {
      return true;
    }
    else {
      return false;
    }
  }

  hasValidSearchCriteria(values) {
    if (values.productId.length === 0 && values.orderId.length === 0) {
      return false;
    }
    return true;
  }

  downloadSDS(hazmatDocument: any) {
    this.isLoading = true;
    this.searchService.downloadSDS(hazmatDocument)
      .subscribe(
        data => {
          this.isLoading = false;
          let blob = new Blob([data], { type: data.type });
          var sdsDocumentLink = document.createElement('a');
          sdsDocumentLink.href = URL.createObjectURL(blob);
          //if (hazmatDocument.FileType == 'msg' || hazmatDocument.FileType == 'xlsm' || hazmatDocument.FileType == 'mdl' || hazmatDocument.FileType == 'bmp') {
          //  sdsDocumentLink.download = hazmatDocument.DocumentName + "." + hazmatDocument.FileType;
          //}
          //else sdsDocumentLink.download = hazmatDocument.DocumentName;
          sdsDocumentLink.download = hazmatDocument.DocumentName + "." + hazmatDocument.FileType;
          sdsDocumentLink.click();
          // TODO: Spinner loading
        },
        err => {
          // TODO: rror handling
          this.isLoading = false;
        
        });
  }
}

