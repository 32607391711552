import { Injectable, Inject } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private baseUrl = "";
  constructor(private httpService: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  getSearchableSeasons(): Observable<Hm.Bopo.HazmatSearch.Core.ViewData.SeasonViewData[]> {
    const seasonUrl = this.baseUrl + "seasons";
    return this.httpService.get<Hm.Bopo.HazmatSearch.Core.ViewData.SeasonViewData[]>(seasonUrl);
  }

  getLanguages(): Observable<Hm.Bopo.HazmatSearch.Core.ViewData.LanguageViewData[]> {
    const languageUrl = this.baseUrl + "languages";
    return this.httpService.get<Hm.Bopo.HazmatSearch.Core.ViewData.LanguageViewData[]>(languageUrl);
  }

  searchSDSDocuments(searchViewData: Hm.Bopo.HazmatSearch.Core.ViewData.SearchViewData):
    Observable<Hm.Bopo.HazmatSearch.Core.ViewData.HazmatDocumentViewData[]> {
    const searchUrl = this.baseUrl + "search";
    return this.httpService.post<any>(searchUrl, searchViewData);
  }

  downloadSDS(hazmatDocumentViewData: Hm.Bopo.HazmatSearch.Core.ViewData.HazmatDocumentViewData): Observable<any> {
    const searchUrl = this.baseUrl + "downloadsds";
    const respType = { responseType: "blob" as "json" };
    return this.httpService.post<any>(searchUrl, hazmatDocumentViewData, respType);
  }
}
