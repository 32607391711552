import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(private router: Router) {
  }
  isValidHazMatTool() {
    return this.router.url.includes('/hazmatTool') || this.router.url.includes('/hazmatInfo') || this.router.url.includes('/noaccess')
  }
}
