import { DOCUMENT } from '@angular/common'
import { Directive, HostBinding, HostListener, Inject } from '@angular/core'
@Directive({
  selector: '[NumbersWithMinus]',
})
export class OnlyNumbersWithMinusDirective {
  @HostBinding('autocomplete') public autocomplete
  constructor(@Inject(DOCUMENT) private document: Document) {
    this.autocomplete = 'off'
  }
  @HostListener('keypress', ['$event']) public disableKeys(e: any) {
    //this.document.all ? e.keyCode : e.keyCode
    return e.keyCode == 8 || (e.keyCode >= 48 && e.keyCode <= 57) || e.keyCode==45
  }
}
