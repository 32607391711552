import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toaster: ToastrService, private snackBar: MatSnackBar) { }

  showNotification(message: string) {
    this.snackBar.open(message, 'OK', {
      duration: 5000,
      panelClass: 'snackbar'
    });
  }

  success(message: string, title: string = 'Success') {
    this.toaster.success(message, title);
  }

  info(message: string, title: string = 'Info') {
    this.toaster.info(message, title);
  }

  error(message: string, title: string = 'Error') {
    this.toaster.error(message, title, {
      titleClass: "center",
      messageClass: "center"
    })
  }

  warning(message: string, title: string = 'Warning') {
    this.toaster.warning(message, title,{
      titleClass: "center",
      messageClass: "center"
    })
  }
}
