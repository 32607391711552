<div class="container hazmat-tool" *ngIf="authenticationService.isUserAccess">
  <div class="row col-12">
    <form [formGroup]="searchform">
      <div class="input-group">
        <label class="productnumber-label">Product Number</label>
        <input type="search" class="form-control rounded" name="productNumbers"
               onlyNumbers id="productNumber" placeholder="Enter Product Number"
               maxlength="7" pattern="0{0,1}[1-9]{1}[0-9]{5,6}" title="0123456 or 123456"
               (keyup.enter)="onClickSearch()"
               required formControlName="productNumber" style=" width: 20% !important; flex: none !important; border: #bfc4c9 1px solid !important; height:40px">
        <button type="button" id="submit" class="btn submit-btn" (click)="onClickSearch()" style="margin-left: 20px !important; border-radius:5px">
          Search &nbsp;
          <i class="fas fa-search" style="font-size: 14px;"></i>
        </button>
      </div>
    </form>
  </div>
</div>
