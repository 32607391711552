import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../authentication/authentication.service';
import { NotificationService } from '../../common/NotificationService';
import { ProductService } from '../product.service';

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.css']
})
export class ProductSearchComponent implements OnInit {
  searchform: FormGroup;
  subscription = new Subscription();

  constructor(
    private router: Router,
    private fbfind: FormBuilder,
    private productService: ProductService,
    public authenticationService: AuthenticationService,
    private notificationService: NotificationService  ) {
  }

  ngOnInit(): void {
    this.searchform = this.fbfind.group({
      productNumber: ['', Validators.required],
    });
      let data = this.authenticationService.checkAndSetActiveUserAccount();
  }

  async onClickSearch() {
    this.searchform.markAllAsTouched();
    let productNumber = this.searchform.value.productNumber;
    if (this.searchform.valid) {
      let data = await this.productService.GetProductInfo(productNumber).toPromise();
      if (data) {
        this.router.navigate(['/hazmatInfo/' + productNumber]);
      }
    }
    else {
      if (!productNumber) {
        this.notificationService.showNotification("Product number is required.");
      }
      if (!this.isValidProductNumber() && productNumber.length > 0) {
        this.notificationService.showNotification("Product Number should be 7 digits - xxxxxxx");
      }
    }
  }
  isValidProductNumber() {
    var regexp = new RegExp('^[0-9][0-9]{6}$');
    if (regexp.test(this.searchform.value.productNumber)) {
      return true;
    }
    else {
      return false;
    }
  }
}
