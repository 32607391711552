<div class="noaccess-container">
  <h3>
    <span class="material-icons"> lock</span><br/> Access Denied!
  </h3>

  <p class="message">
    You do not have enough privileges to access this Application.
  </p>

</div>
